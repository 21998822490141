import React from 'react';
import { graphql } from 'gatsby';
import ReactTooltip from 'react-tooltip';
import Layout from '../components/Layout';
import BackgroundImage from '../components/BackgroundImage';
import { ImageOverlay } from '../utils';
import Image from '../components/Image';
import useSiteMetadata from '../components/SiteMetadata';
import MetaComponent from '../components/MetaComponent';

export const ServiceInclusionsPageTemplate = ({
  image,
  title,
  text,
  helmet,
  announcement,
  tableHeader,
  tableRows,
  serviceTiles,
}) => {
  const renderTableHeader = () => tableHeader.map((title, index) => {
    const renderIcon = () => {
      if (index != 0) {
        return (
          <Image
            imageInfo={{ image: serviceTiles[index-1].image }}
            classes={`service-inclusions-image ${index > 1 ? "service-inclusions-image-padding": "" }`}
            animationClass='hover-zoom'
          />
        )
      }
      return null;
    }
    return (
      <th key={index} className='inclusions-header'>
        { renderIcon() }
        {title}
      </th>
    )
  });

  const renderTableRowItem = (rowItems, tooltip) =>
    rowItems.map((item, index) => {
      if (!!tooltip && index === 0) {
        return (
          <td key={index}>
            {item}
            <a data-tip={tooltip} data-for={`tooltip-${index}`}  className="tooltip-wrapper">?</a>
            <ReactTooltip id={ `tooltip-${index}` } textColor='black' backgroundColor='aliceblue' place="right" type="dark" effect="solid" className="tooltip" />
          </td>
        );
      }
      return <td key={index}>{item}</td>;
    });
  const renderTableRows = () =>
    tableRows.map((row, index) => {
      if (row.type === 'header') {
        return (
          <tr key={index} className='inclusions-row-header'>
            <td colSpan='4'>{row.content}</td>
          </tr>
        );
      }
      return (
        <tr key={index} className='inclusions-row-content'>
          {renderTableRowItem(row.content, row.tooltip)}
        </tr>
      );
    });
  return (
    <div>
      {helmet || ''}
      <BackgroundImage classes={'page-header-image'} imageInfo={image.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={image.overlay} />
      </BackgroundImage>

      <div className='content section'>
      <div className="service-inclusions-title-text">{ text }</div>
        <table className='inclusions-table'>
          <thead>
            <tr>{renderTableHeader()}</tr>
          </thead>
          <tbody>
            {renderTableRows()}
            <tr className='inclusions-announcement'>
              <td colSpan='4'>{announcement}</td>
            </tr>
          </tbody>
        </table>
        <div>✔ = Included in accommodation price</div>
        <div>$ = Additional Cost </div>
        <div>**Please note that some services may be reduced or limited depending on the season or during times of low occupancy**</div>
      </div>
    </div>
  );
};

const ServiceInclusionsPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <ServiceInclusionsPageTemplate
        location={location}
        title={frontmatter.title}
        text={frontmatter.text}
        image={frontmatter.heroImageObject}
        announcement={frontmatter.announcement}
        tableHeader={frontmatter.tableHeader}
        tableRows={frontmatter.tableRows}
        serviceTiles={frontmatter.serviceTiles}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default ServiceInclusionsPage;

export const pageQuery = graphql`
  query serviceInclusionsPageQuery {
    markdownRemark(
      frontmatter: { templateKey: { eq: "service-inclusions-page" } }
    ) {
      frontmatter {
        title
        text
        heroImageObject {
          overlay
          image
        }
        serviceTiles {
          image
        }
        tableHeader
        tableRows {
          type
          content
          tooltip
        }
        seoObject {
          metaTitle
          metaDescription
        }
        announcement
      }
    }
  }
`;
